import React from 'react'
import './Image.css'
function Image({imagePath ,alt}) {
  return (
    <figure className='figure'>
        <img src={imagePath}alt={alt ? alt : 'Image'} width={100}/>
        {/* <figcaption>I am coding</figcaption> */}
    </figure>
  )
}

export default Image